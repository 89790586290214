export const validateMethodOfPayment = (arr) => {
  const parameters = ['icon', 'fee', 'funds', 'title'];
  return arr.map(item => {
    const values = Object.keys(item);
    return parameters.every(item => values.includes(item));
  }).every(item => item === true);
};

export const validateType = (value) => {
  return ['deposit', 'withdraw'].includes(value);
};
