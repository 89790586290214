<template>
  <div :class="modalClasses" role="dialog">
    <div class="flex flex-col gap-s24 capitalize">
      <h3 class="subheadline-large">
        {{ $t(`wallet.modal.limit_type.title`, { type }) }} 
      </h3>
    </div>

    <div class="flex flex-col mt-s32 mb-s40 gap-s24 overflow-y-auto pr-s16">

      <div class="flex flex-col gap-s24" :style="{ maxHeight: '500px' }">
        <ul  v-for="method of methods" :key="method.type">
          <li>
            <h5 class="subheadline-small capitalize">
              {{getLabelName(method.type)}}
            </h5>
          </li>
          <li v-for="value of method.values" :key="`${method.type}-${value.type}`" class="flex justify-between border-b border-border body-text-large py-s12 last:border-b-0">
            <p class="capitalize">
              {{$t(`wallet.limits.${ value.type }`)}}
            </p>
            <p>${{numberFormat(value.amount)}} {{$t('drops.usd')}}</p>
          </li>
        </ul>
      </div>

    </div>

    <div class="flex flex-col items-end gap-s16 border-t border-border -m-s16 p-s16">
      <ButtonV2
        v-if="showIncreaseLimitButton"
        class="capitalize"
        size="large"
        :label="$t('wallet.modal.add_methods.increase_limit', { type })"
        testId="btn-increaseLimit"
        @onClick="showModal('KycStatus')"
        wide
      />
      <ButtonV2
        @onClick="goBack"
        :label="$t('cancel')"
        testId="btn-cancel"
        size="large"
        version="secondary"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { validateType } from '../types';

export default {
  name: 'TransferLimitContent',
  components: {
    ButtonV2
  },
  props: {
    methods: {
      type: Array,
      required: true,
    },
    kycComplete: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: true,
      validator: validateType
    },
  },
  computed: {
    modalClasses(){
      return {
        'modal-content': true,
        'modal-content--large': !this.isMobileDevice,
        'modal-content--small': this.isMobileDevice,
      };
    },

    showIncreaseLimitButton() {
      return this.userPermissions && !this.userPermissions.withdraw;
    },

    userPermissions(){
      return this.$store.getters['user/getUserPermissions'];
    },
  },
  methods: {
    goBack(){
      return this.showModal('WalletAddTransferMethod', { type: this.type, isClosable: false });
    },
    getLabelName(type) {
        switch (type) {
        case 'credit_card':
          return this.$t('wallet.credit_debit_card');
        default:
          return this.$t(`wallet.${ type }`);
        }
    },
  },
  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'DEPOSIT_LIMITS_VIEWED',
    });
  },
};
</script>

<style scoped>
.modal-content {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}

.modal-content--large {
  @apply py-s40 px-s20;
  min-width: 450px;
}

.modal-content--small {
  @apply p-s20;
  min-height: 100vh;
  width: 100%;
}
</style>
