<template>
  <WalletTransferLimitContent v-bind="componentProps" />
</template>

<script>
import WalletTransferLimitContent from '@/modules/walletv2/WalletModals/TransferLimitContent/index.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'TransferLimit',
  components: {
    WalletTransferLimitContent,
  },
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('user', [
      'isKycComplete',
    ]),
    ...mapGetters('wallet', [
      'getDepositLimits',
    ]),
    componentProps(){
    
      return {
        methods: this.getDepositLimits.mappedRemaining || [],
        kycComplete: this.isKycComplete,
        type: this.type,
      };
    }
  }
 
};
</script>
